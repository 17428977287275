var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","items":_vm.items,"fields":_vm.fields,"items-per-page":5,"sorter":{ resetable: true },"items-per-page-select":{ label: 'Cuestionarios por página:',
                                values: [
                                  5,
                                  10,
                                  25,
                                  50,
                                  100,
                                  250,
                                  500
                                ]
      },"table-filter":{label: 'Filtro:', placeholder: 'Comience a escribir...'},"no-items-view":{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'},"pagination":""},scopedSlots:_vm._u([{key:"quest_title",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('a',{staticClass:"text-info",on:{"click":function($event){return _vm.informacionCuestionario(item.acciones)}}},[_vm._v(_vm._s(item.quest_title))])])]}},{key:"video_name",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('a',{staticClass:"text-info",on:{"click":function($event){return _vm.informacionVideo(item.video_id)}}},[_vm._v(_vm._s(item.video_name))])])]}},{key:"acciones",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('CButton',{staticStyle:{"margin-right":"3px"},attrs:{"color":"success","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.editCuestionario(item.acciones)}}},[_vm._v(" Editar ")]),_c('CButton',{staticStyle:{"margin-right":"3px"},attrs:{"color":"danger","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.botonEliminar(item)}}},[_vm._v(" Eliminar ")]),_c('CButton',{staticClass:"bi bi-bar-chart-fill",attrs:{"color":"info","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.informacionStats(item.acciones)}}},[_c('svg',{staticClass:"bi bi-pie-chart-fill",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"}})]),_vm._v(" Gráficos ")]),_c('CModal',{attrs:{"id":"modalElim","title":"Eliminar cuestionario","color":"principal","show":_vm.deleteModal},on:{"update:show":function($event){_vm.deleteModal=$event}}},[_c('p',{staticClass:"text-muted"},[_vm._v(" ¿Está seguro de que desea eliminar el cuestionario con titulo "+_vm._s(_vm.cuestionario.quest_title)+"? Esta acción no se puede deshacer. ")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteCuestionario(_vm.cuestionario.acciones)}}},[_vm._v(" Aceptar ")]),_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v(" Volver ")])],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }