<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <div class="d-flex justify-content-between">
          <select
            id="categorias"
            class="form-select"
            @change="categoryFilterChanged()"
          >
            <option
              v-for="accion in getCategorias()"
              :key="accion.category_id"
            >
              {{ accion.category_name }}
            </option>
          </select>
        </div>
        <CTableWrapper
          :items="datos_show"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Cuestionarios
              <CButton
                class="btn btn-success"
                size="sm"
                @click="addCuestionario()"
              >
                Añadir cuestionario
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaCuestionario";
import axios from "axios";
import $ from "jquery";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { getCategoriasData } from '../../utils/utils';

/**
 * Función para la obtencion de todos los cuestionarios de la BBDD
 * 
 * @returns {Promise<any>} Promesa que al completarse con exito devuelve un arreglo con los cuestionarios
 */
async function getCuestionarioData() {
  let data;
  const token = localStorage.token;
  try {
    await axios.get(
        process.env.VUE_APP_URL + '/user' + process.env.VUE_APP_URL_CUESTIONARIOS ,
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((result) => {
        data = result.data;
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
    return data;
  } catch (error) {
    window.location = "#/500";
  }
}

/**
 * Función para la obtencion de los datos de los diferentes videos de la APP
 * 
 * @returns {Promise<any>} Una promesa que al completarse devuelve los videos
 */
async function getVideoData() {
  let video_data;
  const token = localStorage.token;

  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS, {
    headers: {
      Authorization: token
    },
  }).then((result) => {
    video_data = result.data;
  }).catch((error) => {
    if(error.response) {
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        window.location = "#/pages/login";
      }
      if (error.response.status == 404) {
        window.location = "#/404";
      }
      if (error.response.status == 500) {
        window.location = "#/500";
      }
    }
  }); 

  return video_data;
}

export default {
  name: "CuestionarioTable",
  components: { CTableWrapper },
  data() {
    return {
      datos: [],
      temp: 0,
      tempC: 0,
      tempV: 0,
      datos_show: [],
      categorias: [],
      videos: []
    };
  },
  created() {
    this.getCategorias();
  },
  methods: {
    /**
     * Función que carga las categoría y devuelve los datos a mostrar
     * 
     * @returns {object} Los datos a mostrar
     */
    getCuestionariosData() {
      this.categorias = this.getCategorias();
      return this.getCuestionaryData();
    },
    /**
     * Función que carga los datos obtenidos en los datos a mostrar
     * 
     * @returns {object} Los datos que se estan mostrando
     */
    getCuestionaryData() {
      this.datos_show = this.datos;
      return this.datos_show;
    },
    /**
     * Función para la obtencion formateo de los datos para su representacion
     */
    recibirDatos() {
      this.getCategorias();
      this.getVideos();
      let data = getCuestionarioData();

      data.then((result) => {
        if(localStorage.token == undefined)
          return;

        this.datos = [];
        let element;

        for(let i = 0; i < result.length; i++) {
          element = {};
          element.quest_title = result[i].quest_title;
          element.quest_description = result[i].quest_description;
          let video = {};
          for(let j = 0; j < this.videos.length; j++) {
            if(this.videos[j].video_id == result[i].video_id) {
              video.video_name = this.videos[j].video_name;
              video.video_id = this.videos[j].video_id;
              for(let k = 0; k < this.categorias.length; k++) {
                if(this.categorias[k].category_id == this.videos[j].video_category) {
                  video.category_name = this.categorias[k].category_name;
                  break;
                }
              }
              break;
            }
          }

          element.video_name = video.video_name;
          element.category_name = video.category_name;
          element.acciones = result[i].quest_id;
          element.video_id = video.video_id;
          this.datos.push(element);
        }
        this.getCuestionaryData();
      });
      this.temp++;
    },
    addCuestionario() {
      this.$router.push("/cuestionario/crearCuestionario/");
    },
    /**
     * Función asociada al select que se llama cada vez que se selecciona
     * una categoría.
     */
    categoryFilterChanged() {
      var selectedCategory = $("#categorias").children("option:selected").val();
      this.selectCategorias(selectedCategory);
    },
    /**
     * Función que filtra la tabla para que aparezcan solo
     * los consejos con la categoría indicada.
     * 
     * @param {string} categoria - El nombre de la categoría por la que filtrar.
     */
    selectCategorias(categoria) {
      if (categoria == "Todas") {
        this.datos_show = this.datos;

        return;
      }

      this.datos_show = [];
      for (let i = 0; i < this.datos.length; i++) {
        const element = this.datos[i];
        if (element.category_name == categoria) {
          this.datos_show.push(element);
        }
      }
    },
    /**
     * Función para obtener el nombre de una categoría dada por su ID
     * 
     * @param {number} id - El id de la categoría a buscar su nombre
     * @returns {string} El nombre de la categoría
     */
    getCategoriaNombre(id) {
      let nombre;
      for (let index = 0; index < this.categorias.length; index++) {
        if (this.categorias[index].category_id == id) {
          nombre = this.categorias[index].category_name;
          break;
        }
      }
      return nombre;
    },
    /**
     * Función para la obtención de las categorías de la BBDD
     * 
     * @returns {object[]} Un arreglo con las categorías
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = [
          {
            id:"Todas",
            category_name: "Todas",
          }
        ];

        this.categorias.push(...result);
        this.tempC++;
        this.getVideos();
      });
      return this.categorias;
    },
    /**
     * Función para la obtención de los videos de la BBDD
     * 
     * @returns {object[]} Un arreglo con los videos
     */
    getVideos() {
      if(this.tempV > 0) {
        return this.videos;
      }

      let video_data = getVideoData();

      video_data.then((result) => {
        if(localStorage.token == undefined)
          return;

        this.videos = [];
        let element;

        for(let i = 0; i < result.length; i++) {
          element = {};
          element.video_id = result[i].video_id;
          element.video_name = result[i].video_name;
          element.video_category = result[i].video_category;

          this.videos.push(element);
        }

        this.tempV++;
        this.recibirDatos();
      });

      return this.videos;
    }
  },
};
</script>