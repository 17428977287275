<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="items"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Cuestionarios por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #quest_title="{ item }">
          <td>
            <a
              class="text-info"
              @click="informacionCuestionario(item.acciones)"
            >{{ item.quest_title }}</a>
          </td>
        </template>

        <template #video_name="{ item }">
          <td>
            <a
              class="text-info"
              @click="informacionVideo(item.video_id)"
            >{{ item.video_name }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="editCuestionario(item.acciones)"
            >
              Editar
            </CButton>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="botonEliminar(item)"
            >
              Eliminar
            </CButton>
            <CButton
              color="info"
              shape="pill"
              class="bi bi-bar-chart-fill"
              size="sm"
              @click="informacionStats(item.acciones)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pie-chart-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z" />
              </svg>
              Gráficos
            </CButton>
            <CModal
              id="modalElim"
              title="Eliminar cuestionario"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el cuestionario con titulo
                {{ cuestionario.quest_title }}? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteCuestionario(cuestionario.acciones)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import "core-js/stable";
import "regenerator-runtime/runtime";
import axios from 'axios';

export default {
  name: "Table",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "quest_title",
            label: "Título"
          },
          {
            key: "quest_description",
            label: "Descripción"
          },
          {
            key: "video_name",
            label: "Vídeo"
          },
          {
            key: "category_name",
            label: "Categoría"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      cuestionario: {},
    };
  },
  methods: {
    botonEliminar(cuestionario) {
      this.cuestionario = cuestionario;
      this.deleteModal = true;
    },
    /**
     * Función para eliminar un cuestionario dado por su ID
     * 
     * @param {number} id - El id del cuestionario a eliminar
     */
    deleteCuestionario(id) {
      const token = localStorage.token;
      axios.delete(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CUESTIONARIO + id,{
        headers:{
          Authorization: token,
        },
      }).then(()=>{
        location.reload();
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
      this.deleteModal = false;
    },
    /**
     * Función que nos llevara a la pagina de información de un cuestionario
     * 
     * @param {number} id - El id del cuestionario a ver su información
     */
    informacionCuestionario(id) {
      window.location = "#/cuestionario/infoCuestionario/" + id;
    },
    /**
     * Función que se ejecuta al hacer click en el nombre del video, nos llevará a la página para ver la información del video
     * 
     * @param {number} id - El ID del video a mostrar su informacion
     */
    informacionVideo(id) {
      this.$router.push("/video/infoVideo/" + id);
    },
    /**
     * Función que nos llevara a la pagina de modificación de un cuestionario.
     * 
     * @param {number} id - El ID del cuestionario a modificar
     */
    editCuestionario(id) {
      window.location = "#/cuestionario/editCuestionario/" + id;
    },
    informacionStats(id) {
      this.$router.push("stats_cuestionario/" + id);
    }
  },
};
</script>
