import axios from 'axios';

/**
 * Función para conseguir los datos de los usuarios
 * 
 * @returns {Promise<object>} Devuelve una promesa que al resolverse nos da los datos de los usuarios
 */
export async function getUsersData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_USUARIOS + "all", {
            headers: {
                Authorization: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}

/**
 * Función para conseguir los datos de las categorías
 * 
 * @returns {Promise<object>} Promesa que al resolverse da las categorias
 */
export async function getCategoriasData() {
    let data;
    const token = localStorage.token;
    await axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CATEGORIAS, {
            headers: {
                Authorization: token,
            },
        })
        .then((result) => {
            data = result.data;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    localStorage.removeItem("token");
                    window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                    window.location = "#/404";
                }
                if (error.response.status == 500) {
                    window.location = "#/500";
                }
            }
        });
    return data;
}